$(document).on 'click', '.form_submit', (e) ->
  e.preventDefault()
  submit = []
  form_class = $(this).data('class-name')
  $('.input_validation').each ->
    class_name = $(this).data('class-name')
    $('.'+class_name).addClass('d-none')
    if $(this).val() == ''
      $('.'+class_name).removeClass('d-none')
      submit.push(true)
  $this = $(this);
  $this.button('loading')
  setTimeout (->
    $this.button 'reset'
    return
  ), 3000
  if submit.length == 0
    $('.'+form_class).submit()
  else
    error_message_top = $('.alert-colour').not('.d-none').offset().top
    $('.main-panel').scrollTop(error_message_top)