$(document).on "turbolinks:load", () ->  
  $('.date-range-picker').daterangepicker
    showDropdowns: true
    autoUpdateInput: false
    locale:
      format: 'DD/MM/YYYY'
      cancelLabel: 'Clear'
  $('.date-range-picker').on 'apply.daterangepicker', (ev, picker) ->
    $(this).val picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
    $('.date-range-picker').change()
    return
  $('.date-range-picker').on 'cancel.daterangepicker', (ev, picker) ->
    cancel_datarangepicker($(this))

cancel_datarangepicker = ($daterangepicker) ->
  today = get_today_date()
  $daterangepicker.data('daterangepicker').setStartDate(today);
  $daterangepicker.data('daterangepicker').setEndDate(today);
  $daterangepicker.val ''
  $daterangepicker.change()
  $daterangepicker.parent().addClass('hidden').width('')
  $daterangepicker.parent().siblings().removeClass('hidden')
  return

get_today_date = () ->
  today = new Date
  dd = today.getDate()
  mm = today.getMonth() + 1
  yyyy = today.getFullYear()
  if dd < 10
    dd = '0' + dd
  if mm < 10
    mm = '0' + mm
  today = dd + '/' + mm + '/' + yyyy
  return today

# ------------ Renderin barcode in order show ---------------#

$(document).on 'turbolinks:load', () ->
  invalid_barcodes = []
  $(".barcode, .sku-barcode").each ->
    barcode = $(this)[0]
    try
      JsBarcode(barcode).init()
    catch err
      invalid_barcodes.push $(this).data('upc')
      $(this).remove()
  if invalid_barcodes.length > 0
    $('.barcode-content').removeClass 'd-none'
    $('.export-link').addClass 'd-none'
    $('.invalid-barcode-section').removeClass 'd-none'
    $.each invalid_barcodes, (index, value)->
      upc_p = '<div class="row pl-5"><p><div class="font-weight-bold">' + value + '</div> &nbsp; is not a valid UPC</p></div>'
      $('#invalid_barcodes').append upc_p

#------------------------------------------------------------#

status_transition = (order_id, behaviour, rejection_reason, action_name) ->      
  $.ajax
    type: 'POST'
    url: '/admin/orders/' + order_id + '/status_change'
    data: {
      status: behaviour
      rejection_reason: rejection_reason
      action_name: action_name
    }
    success: (data, textStatus, jqXHR) ->
      $('#order-tr-' + order_id).html data
      $.jGrowl("Order-" + order_id + " is moved to " + behaviour) life: 2000
      $('#order_status_modal').modal('hide')
    error: (jqXHR, textStatus, errorThrown) ->
      $.jGrowl("There is some error in order" + order_id) life: 2000

for order_status,i in ['approve', 'reject', 'in_progress', 'ready', 'complete']
  $(document).on 'click', '[data-behaviour=' + order_status + ']', () ->
    behaviour = $(this).data('behaviour')
    order_id = $(this).data('order-id')
    rejection_reason = $('[data-input="rejection-reason"]').val()
    if behaviour == 'reject'
      if rejection_reason == ''
        $('.rejection_div').removeClass('d-none')
      else
        $ -> status_transition(order_id, behaviour, rejection_reason, null)
    else
      $ -> status_transition(order_id, behaviour, null, null)

  $(document).on 'click', '[data-status=' + order_status + ']', () ->
    status = $(this).data('status')
    action = $(this).text()
    $('#order-status-update').attr('data-behaviour',status)
    $('.heading').text(action)

$(document).on 'click', '[data-behaviour="download-pdf"]', () ->
  barcode_svg = $(this).closest('tr').find('.barcode')[0]
  if barcode_svg == undefined || barcode_svg == ""
    $.jGrowl('UPC ' + $(this).data('upc') + ' for this barcode is not valid') life: 2000
  else
    order_id = $(this).data('order-id')
    oi_id = $(this).data('oi-id')
    barcode_svg_string = barcode_svg.outerHTML
    barcode_svg_string = barcode_svg_string.replace(/style="font: 10px Helvetica"/g, 'font-size="7"')
    $.ajax
      type: 'PATCH'
      url: '/admin/orders/' + order_id + '/order_items/' + oi_id
      data: {
        barcode_svg: barcode_svg_string
      }
      success: (data, textStatus, jqXHR) ->

      error: (jqXHR, textStatus, errorThrown) ->
        $.jGrowl("There is some error") life: 2000
  sku_barcode_svg = $(this).closest('tr').find('.sku-barcode')[0]
  if sku_barcode_svg == undefined || sku_barcode_svg == ""
    $.jGrowl('UPC ' + $(this).data('upc') + ' for this barcode is not valid') life: 2000
  else
    order_id = $(this).data('order-id')
    oi_id = $(this).data('oi-id')
    sku_barcode_svg_string = sku_barcode_svg.outerHTML
    # sku_barcode_svg_string = sku_barcode_svg_string.replace(/156/g, '46')
    $.ajax
      type: 'PATCH'
      url: '/admin/orders/' + order_id + '/order_items/' + oi_id
      data: {
        sku_barcode_svg: sku_barcode_svg_string
      }
      success: (data, textStatus, jqXHR) ->

      error: (jqXHR, textStatus, errorThrown) ->
        $.jGrowl("There is some error") life: 2000


$(document).on 'change', '[data-behaviour="company"]', () ->
  company_id = $(this).val()
  if company_id != ''
    $.ajax '/admin/companies/' + company_id + '/vendors_list.json',
      type: 'GET'
      data: {}
      success: (data, jqxhr, textStatus) ->
        $('[data-behaviour="company-select"]').empty()
        $('[data-behaviour="company-select"]').append(new Option('Select vendor', ''))
        $(data).each ->
          $('[data-behaviour="company-select"]').append(new Option($(this)[0].name, $(this)[0].id))
      error: (jqxhr, textStatus, errorThrown) ->
        unless errorThrown == 'abort'
          $.jGrowl("There is some error") life: 2000
          
$(document).on 'click', '[data-behaviour="download-preview-pdf"]', () ->
  element = $(this)
  $ -> render_barcode_svg(element)

render_barcode_svg = (element) ->
  element.text("Loading ...").prop('disabled', true)
  barcode_svgs = $('[data-behaviour="barcode-svg"]')
  order_id = element.data('order-id')
  barcodes = []
  if barcode_svgs.length > 0
    barcode_svgs.each ->
      barcode_svg_string = $(this)[0].outerHTML
      barcode_svg_string = barcode_svg_string.replace(/style="font: 10px Helvetica"/g, 'font-size="7px"')
      barcodes.push({oi_id: $(this).data('oi-id'), barcode_svg:  barcode_svg_string})
    $.ajax
      type: 'PATCH'
      url: '/admin/orders/' + order_id + '/update_barcode'
      data: {
        barcodes: barcodes,
        export_type: element.data('export-type')
      }
      success: (data, textStatus, jqXHR) ->
        # if data.export_type == 'barcode_strings'
        #   $ -> generate_export_file(order_id)
        # else
        window.location.reload()
        if data.export_type == 'preview'
          window.open('/admin/orders/' + order_id + '.pdf')
        else if data.export_type == 'export'
          window.open('/admin/orders/' + order_id + '/order_export.pdf')
      error: (jqXHR, textStatus, errorThrown) ->
        $.jGrowl("There is some error") life: 2000

# ------------------- Export file generation --------------------------#

$(document).on 'turbolinks:load', () ->
  if $('#render-barcode-svg').length > 0
    $('#render-barcode-svg').trigger('click')

generate_export_file = (order_id) ->
  $.ajax
    type: 'PATCH'
    url: '/admin/orders/' + order_id + '/generate_export'
    data: {}
    success: (data, textStatus, jqXHR) ->
      status_message = data.message
      $.jGrowl(status_message) life: 2000
    error: (jqXHR, textStatus, errorThrown) ->
      $.jGrowl("There is some error") life: 2000

#----------------------------------------------------------------------#

$(document).on 'click', '[data-behaviour="status-label"]', () ->
  $(this).closest('tr').find('[data-behaviour="status-label-div"]').addClass('d-none')
  $(this).closest('tr').find('[data-behaviour="status-div"]').removeClass('d-none')
  if $(this).closest('tr').find('[data-behaviour="status-div"]').length == 0
    $(this).closest('tr').find('[data-behaviour="status-label-div"]').removeClass('d-none')
    $(this).closest('tr').find('[data-behaviour="status-div"]').addClass('d-none')

$(document).click (e) ->
  if $(e.target).closest('.status-block').length > 0
    $('[data-behaviour="status"]').val('')
    return false
  else
    $('[data-behaviour="status-label-div"]').removeClass('d-none')
    $('[data-behaviour="status-div"]').addClass('d-none')

$(document).on 'change', '[data-behaviour="status"]', () ->
  status = $(this).val()
  order_id = $(this).data('order-id')
  $('[data-behaviour="rejection"]').attr('data-order-id', order_id)
  if status == 'approved'
    status = 'approve'
  else if status == 'completed'
    status = 'complete'
  if status == 'rejected'
    status = 'reject'
    $('#order_rejection_modal').modal('show')
    $('.rejection_div').addClass('d-none')
  else
    $ -> status_transition(order_id, status, null, 'index')

$(document).on 'click', '[data-behaviour="rejection"]', () ->
  status = $(this).data('status')
  order_id = $(this).data('order-id')    
  rejection_reason = $('[data-input="rejection-reason"]').val()
  if rejection_reason == ''
    $('.rejection_div').removeClass('d-none')
  else
    $('#order_rejection_modal').modal('hide')
    $ -> status_transition(order_id, status, rejection_reason, 'index')

$(document).on 'turbolinks:load', () ->
  if location.href.indexOf('turbolinks=false') > 0
    url = location.href.replace('?turbolinks=false', '')
    window.location.replace(url)

$(document).on 'change', '#label_type', () ->
  if $(this).val() == 'square_shape'
    $('.barcode-type').removeClass('d-none')
    $('.barcode-type select').addClass('input_validation')
  else if $(this).val() == 'key_shape'
    $('.barcode-type').addClass('d-none')
    $('.barcode-type select').removeClass('input_validation')
