fields_check = () ->
  move_forward = []
  $('#invite_form_operations *').filter('.input_validation').each ->
    class_name = $(this).data('class-name')
    $('.' + class_name).addClass('d-none')
    if $(this).val() == ''
      $('.' + class_name).removeClass('d-none')
      move_forward.push('.' + class_name)
  return move_forward 

$(document).on 'click', '[data-behaviour="invitation-submit"]', (e) ->
  e.preventDefault()
  role = $('[data-behaviour="role"]').data('role')
  selected_role = $('[data-class-name="role_div"]').val()
  $('[data-behaviour="company"]').attr('name', 'user[company_id]')
  $('[data-behaviour="company-select"]').removeClass('input_validation')
  $('[data-behaviour="company"]').removeClass('input_validation')
  if selected_role == 'primary'
    $('[data-behaviour="company"]').addClass('input_validation')
  else if selected_role == 'vendor'
    $('[data-behaviour="company-select"]').attr('name', 'user[company_id]')
    $('[data-behaviour="company"]').attr('name', '')    
    $('[data-behaviour="company-select"]').addClass('input_validation')
  if role == 'primary' && selected_role == 'vendor' && $('[data-behaviour="company"]').val() != "" && $('[data-behaviour="company-select"]').val() == ""
    $('[data-behaviour="company-select"]').addClass('input_validation')
    $('[data-behaviour="company-select"]').attr('name', 'user[company_id]')
    $('[data-behaviour="company"]').attr('name', '')

  is_valid = fields_check()
  if is_valid <= 0
    $('.invitation-form').submit()
  else
    error_message_top = $('.alert-colour').not('.d-none').offset().top
    $('.main-panel').scrollTop(error_message_top)   

$(document).on 'click', '[data-redirect="company"]', (e) ->
  history.go(-1);

$(document).on 'change', '[data-class-name="role_div"]', () ->
  role = $(this).val()
  $('[data-input="company"]').addClass('d-none')
  $('[data-input="vendor"]').addClass('d-none')
  if role == 'primary'
    $('[data-input="company"]').removeClass('d-none')
  else if role == 'vendor'
    $('[data-input="company"]').removeClass('d-none')
    $('[data-input="vendor"]').removeClass('d-none')