$(document).on 'change', '[data-behaviour=company-select]', () ->
  company_id = $(this).val()
  $('#add-address').addClass('d-none')
  if company_id != ''
    $('#add-address').removeClass('d-none')
  $.ajax
    type: 'GET'
    url: '/admin/companies/' + company_id + '/addresses'
    data: {}
    success: (data, textStatus, jqXHR) ->
      $('.addresses_list').html(data)
    error: (jqXHR, textStatus, errorThrown) ->

$(document).on 'click', '[data-behaviour=address-checkbox]', () ->
  $('#shipping_address_id').val($(this).val())

$(document).on 'click', '[data-behaviour=add-address]', () ->
  company_id = $('[data-behaviour=company-select]').val()
  $.ajax
    type: 'POST'
    url: '/admin/companies/' + company_id + '/addresses'
    data: {
      company_id: company_id,
      english_address: $('[data-behaviour=english-address]').val(),
      chinese_address: $('[data-behaviour=chinese-address]').val()
    }
    success: (data, textStatus, jqXHR) ->
      $('[data-behaviour=company-select]').val(company_id)
      $('#addressModal').modal('hide');
      $.when($('.addresses_list').html(data)).then ->
        $.jGrowl("Address succefully added") life: 2000
    error: (jqXHR, textStatus, errorThrown) ->
      $.jGrowl("There is some error") life: 2000
